<template>
  <div class="brew-grid">
    <!-- <div class="brew-card" v-for='b in brews' :key='b.flowmeter_id'> -->
      <card v-for='b in brews' :key='b.flowmeter_id' :brew="b" :brew_sessions="brew_sessions" v-on:keg-refilled="keg_refilled($event)" class="brew-card"></card>
    <!-- </div> -->
  </div>
</template>


<script>
import card from '../components/card.vue'
import monitor from '../static/monitor.js'

function flowmessage(msg) {
  let brew = this.brews[msg.flowmeter_id];
  let flow = (msg.cumulative_flow * 1000.0) / brew.pulses_per_litre;

  if (!brew.remaining_at_start_of_flow){
      //remember the remaining amount
      brew.remaining_at_start_of_flow = brew.remaining;
  }
  if (!brew.pulses_at_start_of_flow){
      //remember the remaining amount
      brew.pulses_at_start_of_flow = 0;
  }

  brew.pulses = msg.cumulative_flow + brew.pulses_at_start_of_flow;
  brew.remaining = brew.remaining_at_start_of_flow - flow;

  if (!msg.flowing) {
      brew.remaining_at_start_of_flow = brew.remaining;
      brew.pulses_at_start_of_flow = brew.pulses;
  }

  console.log(`${msg.flowmeter_id}: ${brew.remaining} remaining, ${brew.pulses} pulses`);
}


export default {
  created() {
    this.api_base_url = "https://api.kegshow.com/v1/" + this.$route.params.user;
    this.fetchBrews(true);

    var axios2 = require('axios');
    var config = {
      method: 'get',
      url: this.api_base_url + '/brewsessions',
      headers: {
          'X-API-KEY': '6335e0726e4e2aec6ec1bc136b45c6dbe781a071'
      }
    };

    var self = this;
    axios2(config)
      .then(function (response) {
          // console.log(JSON.stringify(response.data));
          self.brew_sessions = response.data.brewsessions;
      })
      .catch(function (error) {
          console.log(error);
      });
  },
  data() {
    return {
      brews: {},
      brew_sessions: [],
      api_base_url: ''
    }
  },
  components: {
    card
  },
  methods: {
    flowmessage,
    fetchBrews: function(startMonitoring) {
      const axios = require('axios').default;
      var self = this;
      axios.get(self.api_base_url + "/brew")
        .then(function (response) {
          var bs = {}
          console.log(response);
          for (let b of response.data.brews) {
            b.pulses = 0;
            bs[b.flowmeter_id]=b;
          }
          self.brews = bs;
          if (startMonitoring) {
            monitor(response.data.devices, self.flowmessage);
          }
        });
    },
    keg_refilled: function(/*newBrew*/) {
      // Could be smarter about this and only update the relevant fields from newBrew
      this.fetchBrews();
    }
  }
}
</script>

<style scoped>

.brew-grid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
}

.brew-card {
  margin: 15px;
}

</style>