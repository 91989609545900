<template>
    <svg version="1.1" viewBox="0 0 370 470">

      <defs>
        <clipPath id="tulip-glass">
          <path transform="scale(1.095, 1.095)" d="M76,0c-0.43-0.025-0.869,0.047-1.257,0.238c-0.501,0.24-0.914,0.657-1.149,1.161
			c-0.237,0.502-0.295,1.086-0.16,1.625c0.091,0.363,0.23,0.615,0.439,0.917c0.05,0.086,0.086,0.18,0.105,0.277
			c0.011,0.052,0.017,0.106,0.018,0.16c0.004,0.006-0.111,7.194-0.109,7.208c-0.426,17.881-1.783,36.212-4.883,53.83
			c-2.542,14.56-6.639,29.518-10.881,43.683c-1.178,4.01-10.94,34.651-11.694,37.734c-0.936,3.428-1.775,7.04-2.438,10.53
			c-1.916,10.067-2.703,20.396-2.337,30.637c0.788,22.59,7.251,44.996,18.775,64.453c17.017,28.874,44.789,50.521,77.322,58.951
			l-1.302,5.921c9.002,8.003,12.675,17.487,13.531,29.298c0.437,5.856,0.4,11.828-0.576,17.629
			c-0.731,4.361-2.02,8.652-3.915,12.651c-1.081,2.271-2.342,4.5-3.819,6.537c-1.098,1.511-2.345,2.931-3.683,4.234
			c-4.225,4.084-9.303,7.232-14.418,10.076c-10.384,5.678-21.942,10.444-32.946,14.826c-4.699,1.844-14.277,5.45-19.038,7.188
			c-0.143,0.054-0.284,0.12-0.416,0.196c-0.215,0.125-0.415,0.279-0.59,0.457c-0.508,0.512-0.802,1.229-0.799,1.951
			c0,0,0,3.736,0,3.736s0,0.031,0,0.031c-0.002,0.026,0.003,0.067,0.003,0.093c0.007,0.149,0.025,0.3,0.056,0.447
			c0.095,0.454,0.306,0.885,0.609,1.237c0.278,0.325,0.632,0.585,1.025,0.751c0.266,0.113,0.55,0.184,0.838,0.21
			c-0.081,0.131,96.196-0.071,96.221,0.012c0.259-0.18,95.981,0.143,96.221-0.012c0.288-0.026,0.573-0.097,0.838-0.21
			c0.393-0.167,0.748-0.426,1.025-0.751c0.266-0.31,0.462-0.681,0.57-1.075c0.036-0.137,0.066-0.278,0.081-0.419
			c0.006-0.083,0.018-0.168,0.017-0.252c0.001-0.015,0-0.047,0.001-0.062c0,0,0-3.736,0-3.736c0.002-0.722-0.29-1.438-0.799-1.951
			c-0.181-0.184-0.388-0.343-0.613-0.469c-0.133-0.075-0.273-0.14-0.416-0.192c-4.814-1.76-14.281-5.32-19.014-7.179
			c-11.023-4.386-22.553-9.144-32.947-14.826c-5.115-2.844-10.192-5.991-14.418-10.076c-1.269-1.235-2.458-2.579-3.513-4.002
			c-1.45-1.958-2.689-4.108-3.761-6.294c-1.944-3.983-3.284-8.269-4.057-12.63c-1.06-5.959-1.111-12.102-0.662-18.125
			c0.857-11.814,4.529-21.294,13.531-29.298l-1.302-5.921c37.248-9.667,67.798-36.383,83.749-71.229
			c10.346-22.471,14.354-47.795,11.586-72.37c-0.788-7.055-2.139-14.124-4.012-20.972c-7.468-24.937-16.928-51.696-21.803-77.24
			c-3.659-18.975-5.193-38.698-5.654-58.007c0,0-0.109-7.181-0.109-7.181c0-0.024,0.001-0.055,0.003-0.08
			c0.009-0.107,0.037-0.214,0.083-0.312c0,0,0.004-0.008,0.004-0.008s0.004-0.008,0.004-0.008s0.004-0.008,0.004-0.008
			s0.004-0.008,0.004-0.008s0.004-0.008,0.004-0.008s0.004-0.008,0.004-0.008s0.004-0.008,0.004-0.008l0.004-0.008
			c0,0,0.004-0.008,0.004-0.008s0.005-0.008,0.005-0.008s0.005-0.008,0.005-0.008s0.005-0.008,0.005-0.008s0.005-0.008,0.005-0.008
			s0.005-0.007,0.005-0.007s0.005-0.007,0.005-0.007l0.005-0.007c0,0,0.005-0.007,0.005-0.007s0.005-0.007,0.005-0.007l0.005-0.007
			l0.019-0.027c0.05-0.068,0.098-0.15,0.14-0.224c0.195-0.352,0.3-0.754,0.304-1.156c0.006-0.491-0.142-0.983-0.418-1.389
			c-0.28-0.416-0.693-0.743-1.164-0.919c-0.335-0.131-0.699-0.17-1.056-0.152H106.098z"/>
        </clipPath>
        <clipPath id="tulip-fill">
          <path transform="scale(1.095, 1.095)" d="M83,0
            c-0.237,0.502-0.295,1.086-0.16,1.625
            c0.091,0.363,0.23,0.615,0.439,0.917
            c0.05,0.086,0.086,0.18,0.105,0.277
            c0.011,0.052,0.017,0.106,0.018,0.16
            c0.004,0.006-0.111,7.194-0.109,7.208
            c-0.426,17.881-1.783,36.212-4.883,53.83
            c-2.542,14.56-6.639,29.518-10.881,43.683
            c-1.178,4.01-10.94,34.651-11.694,37.734
            c-0.936,3.428-1.775,7.04-2.438,10.53
            c-1.916,10.067-2.703,20.396-2.337,30.637
            c0.788,22.59,7.251,44.996,18.775,64.453

    c17.017,28.874,44.789,50.521,97.322,53.951

    c54.248,-4.667,80.798,-25.383,106.749-66.229

    c10.346-22.471,14.354-47.795,11.586-72.37
    c-0.788-7.055-2.139-14.124-4.012-20.972
    c-7.468-24.937-16.928-51.696-21.803-77.24
    c-3.659-18.975-5.193-38.698-5.654-58.007
    c0,0-0.109-7.181-0.109-7.181
    c0-0.024,0.001-0.055,0.003-0.08
    c0.009-0.107,0.037-0.214,0.083-0.312
    c0,0,0.004-0.008,0.004-0.008s0.004-0.008,0.004-0.008s0.004-0.008,0.004-0.008
    s0.004-0.008,0.004-0.008s0.004-0.008,0.004-0.008s0.004-0.008,0.004-0.008s0.004-0.008,0.004-0.008l0.004-0.008
    c0,0,0.004-0.008,0.004-0.008s0.005-0.008,0.005-0.008s0.005-0.008,0.005-0.008s0.005-0.008,0.005-0.008s0.005-0.008,0.005-0.008
    s0.005-0.007,0.005-0.007s0.005-0.007,0.005-0.007l0.005-0.007c0,0,0.005-0.007,0.005-0.007s0.005-0.007,0.005-0.007l0.005-0.007
    l0.019-0.027c0.05-0.068,0.098-0.15,0.14-0.224
    c0.195-0.352,0.3-0.754,0.304,-2.156
    H115.098z"/>
        </clipPath>
        <clipPath id="irish-glass">
          <path d="M79,4
            c0.869,0.124,218.098-0.118,218.88,0.024
            c2.541,0.254,4.063,3.347,2.714,5.515
            c-0.157,0.239-0.281,0.496-0.31,0.784
            c6.276,57.495,8.496,116.182,0.378,173.589
            c-4.109,32.446-16.492,67.335-22.154,99.503
            c-9.261,54.95-4.615,111.588,0.748,166.751
            c0.355,6.703-4.136,13.244-10.529,15.306
            c-41.835,1.648-118.409,1.121-160.396,0.297
            c-6.986-1.792-11.956-8.598-11.501-15.803
            c5.534-56.515,10.24-114.71-0.046-170.921
            c-3.255-17.75-11.18-45.018-15.276-62.716
            c-14.313-62.892-13.162-128.499-7.145-192.385
            c0.011-0.023,1.425-13.264,1.433-13.287
            c0.066-0.477-0.173-0.899-0.408-1.289
            c-1.363-2.466,0.819-5.686,3.612-5.368z"/>
        </clipPath>
        <clipPath id="irish-fill">
          <path d="M82,5
            c-6.919,62.588-9.534,126.946,1.419,189.211
            c6.179,34.304,19.845,71.984,22.819,106.821
            c3.213,29.623,2.875,60.145,1.169,89.87
            c-0.088,6.431-2.702,27.56-1.409,33.982
            c2.178,12.782,17.351,15.13,28.144,15.465
            c29.557,0.78,71.185,0.4,100.794,0.212
            c10.964-0.386,26.487-0.16,32.783-10.853
            c2.286-4.147,2.134-9.079,1.942-13.676
            c-3.344-44.192-5.217-89.408,1.925-133.303
            c3.029-19.627,11.694-49.808,16.386-69.314
            c15.493-67.859,13.087-138.927,5.41-207.69z"/>
        </clipPath>
        <clipPath id="ipa-glass">
          <path transform="scale(0.87)"
             d="M125.5,0c0.493,-0.002,177.416,0.003,177.868,-0.002c0.467,0.028,0.928,0.162,1.335,0.39c1.435,0.763,2.031,2.689,1.277,4.129c7.64,55.225,20.326,131.937,25.076,187.256c0.778,11.037,1.544,22.547,1.232,33.575c-0.421,12.143,-2.743,24.262,-6.839,35.701c-4.582,12.814,-11.424,24.858,-20.084,35.356c-8.59,10.335,-20.649,20.962,-28.409,31.947c-2.794,4.039,-5.436,8.582,-5.611,13.613c-0.046,1.642,0.193,3.323,0.77,4.866c2.294,6.788,3.667,13.941,4.178,21.086c0.479,6.751,0.165,13.588,-0.873,20.273c-0.153,1.205,-0.106,2.437,0.138,3.627c0.131,0.641,0.321,1.274,0.562,1.881c1.331,3.08,2.677,6.334,3.686,9.534c3.661,11.15,4.096,23.117,2.635,34.695c-0.128,1.682,0.136,3.393,0.763,4.958c6.073,11.752,8.467,25.203,7.529,38.361c-0.093,1.717,-0.405,3.599,-0.507,5.313c-0.016,1.248,0.181,2.501,0.578,3.684c3.271,9.218,6.589,19.878,4.189,29.635c-1.248,4.853,-4.759,9.254,-9.632,10.781c-2.308,0.773,-4.761,1.096,-7.176,1.316c-14.139,0.887,-49.333,0.255,-63.998,0.437c-14.202,-0.574,-49.173,0.672,-62.903,-0.351c-2.775,-0.218,-5.619,-0.513,-8.271,-1.402c-4.87,-1.532,-8.386,-5.925,-9.632,-10.781c-2.404,-9.74,0.925,-20.44,4.189,-29.635c0.398,-1.183,0.595,-2.436,0.578,-3.684c-0.103,-1.711,-0.424,-3.588,-0.507,-5.313c-0.936,-13.159,1.455,-26.608,7.529,-38.361c0.627,-1.566,0.89,-3.277,0.762,-4.958c-1.461,-11.578,-1.027,-23.546,2.635,-34.695c1.012,-3.199,2.349,-6.457,3.686,-9.534c0.23,-0.577,0.412,-1.177,0.542,-1.784c0.255,-1.188,0.313,-2.421,0.17,-3.628c-1.04,-6.717,-1.37,-13.587,-0.885,-20.37c0.512,-7.144,1.884,-14.298,4.178,-21.086c0.58,-1.539,0.815,-3.225,0.77,-4.866c-0.175,-5.031,-2.816,-9.573,-5.611,-13.613c-8.062,-11.41,-20.518,-22.182,-29.285,-33.022c-8.246,-10.237,-14.782,-21.903,-19.208,-34.281c-3.94,-11.004,-6.242,-22.647,-6.783,-34.323c-1.006,-60.211,18.944,-161.739,26.257,-222.209c-1.097,-2.191,0.671,-4.737,3.102,-4.516z"/>
        </clipPath>
        <clipPath id="ipa-fill">
            <path transform="scale(0.87)"
    d="M128,0
    c-7.418,63.265,-26.84,160.353,-26.485,223.112
    c0.533,13.339,3.493,26.644,8.657,38.955
    c5.145,12.272,12.52,23.687,21.632,33.387
    c7.182,7.756,16.461,16.835,22.737,25.234
    c2.637,3.564,5.088,7.373,6.562,11.578
    c1.25,3.522,1.72,7.373,0.999,11.061
    c-0.358,1.932,-1.185,3.84,-1.695,5.735
    c-1.332,4.579,-2.233,9.352,-2.717,14.095
    c-0.687,6.777,-0.526,13.688,0.447,20.428
    c0.28,1.622,0.398,3.312,0.224,4.953
    c-0.155,1.573,-0.519,3.142,-1.101,4.612
    c-1.128,2.62,-2.318,5.442,-3.222,8.148
    c-2.505,7.329,-3.608,15.122,-3.481,22.857
    c0.04,3.336,0.311,6.773,0.711,10.085
    c0.086,0.77,0.119,1.557,0.098,2.332
    c-0.048,1.763,-0.377,3.522,-0.969,5.183
    c-0.607,1.71,-1.574,3.291,-2.267,4.965
    c-3.851,8.773,-5.45,18.459,-5.25,28.01
    c0.046,2.057,0.188,4.299,0.412,6.347
    c0.279,1.558,0.339,3.175,0.172,4.75
    c-0.188,2.342,-1.163,4.518,-1.891,6.722
    c-0.59,1.9,-0.944,3.9,-0.875,5.895
    c0.235,8.46,9.218,12.481,16.599,13.215
    c2.501,0.347,5.198,0.443,7.721,0.541
    c27.268,-0.015,73.474,0.377,100.72,-0.122
    c3.738,-0.234,7.574,-0.59,11.133,-1.824
    c3.929,-1.353,7.725,-3.948,9.401,-7.87
    c1.352,-3.251,0.909,-6.938,-0.163,-10.204
    c-0.909,-2.475,-1.852,-4.988,-1.859,-7.661
    c-0.081,-1.803,0.35,-3.747,0.458,-5.553
    c0.409,-5.195,0.273,-10.507,-0.435,-15.67
    c-0.783,-5.69,-2.293,-11.312,-4.603,-16.576
    c-0.887,-2.105,-2.137,-4.068,-2.682,-6.303
    c-0.412,-1.554,-0.599,-3.171,-0.554,-4.779
    c0.171,-2.674,0.603,-5.486,0.698,-8.166
    c0.469,-8.448,-0.403,-17.024,-3.015,-25.09
    c-1.056,-3.561,-2.765,-7.011,-4.018,-10.495
    c-0.558,-1.846,-0.807,-3.798,-0.728,-5.725
    c0.177,-2.454,0.689,-4.959,0.848,-7.42
    c1.107,-11.77,-0.619,-23.653,-4.397,-34.815
    c-1.658,-7.091,1.29,-14.251,5.207,-20.072
    c9.155,-13.641,23.218,-24.876,33.02,-38.016
    c10.227,-13.686,17.277,-29.783,20.41,-46.578
    c1.81,-9.302,2.137,-18.981,1.808,-28.435
    c-2.358,-62.24,-18.861,-148.84,-26.385,-211.199z


    "


    />

        </clipPath>
        <clipPath id="american-glass">
          <path transform="scale(1.074)"
            d="M290.5,0c0.952,-0.001,1.875,0.477,2.412,1.265c0.231,0.334,0.392,0.716,0.468,1.115c0.043,0.225,0.06,0.453,0.05,0.681c-0.082,0.822,-32.629,423.059,-32.753,423.824c-0.143,1.018,-0.421,2.022,-0.824,2.966c-0.516,1.214,-1.242,2.344,-2.132,3.317c-2.073,2.285,-5.076,3.692,-8.16,3.814h-155.264c-2.851,-0.117,-5.637,-1.319,-7.677,-3.315c-0.823,-0.801,-1.53,-1.725,-2.086,-2.73c-0.469,-0.848,-0.834,-1.757,-1.081,-2.694c-0.127,-0.487,-0.225,-0.982,-0.29,-1.48c-0.057,-0.037,-32.703,-423.475,-32.738,-423.735c-0.019,-0.548,0.121,-1.101,0.397,-1.573c0.277,-0.476,0.693,-0.873,1.183,-1.126c0.319,-0.165,0.671,-0.273,1.028,-0.312c0.305,-0.236,237.138,0.135,237.467,-0.017z"/>
        </clipPath>
        <clipPath id="american-fill">
          <path transform="scale(1.074)"
            d="M288,0
            c-0.134,0.25,-0.231,0.519,-0.288,0.797
            c-0.089,0.299,-29.538,381.956,-29.586,382.287
            c-0.342,3.461,-0.711,7.027,-1.649,10.382
            c-0.414,1.458,-0.961,2.901,-1.714,4.22
            c-0.732,1.286,-1.676,2.464,-2.757,3.473
            c-2.5,2.334,-5.675,3.809,-8.89,4.884
            c-2.487,0.831,-5.077,1.455,-7.68,1.783
            c-2.274,0.3,-4.759,0.487,-7.049,0.627
            c-14.472,0.849,-41.608,0.684,-56.371,0.809
            c-14.936,-0.126,-41.801,0.041,-56.373,-0.81
            c-2.294,-0.14,-4.774,-0.326,-7.049,-0.627c-2.603,-0.328,-5.193,-0.952,-7.68,-1.783
            c-3.215,-1.075,-6.39,-2.55,-8.89,-4.884
            c-1.082,-1.009,-2.025,-2.187,-2.757,-3.473
            c-0.753,-1.319,-1.3,-2.762,-1.714,-4.22
            c-0.937,-3.354,-1.307,-6.923,-1.649,-10.382
            c-0.101,-0.221,-29.458,-382.046,-29.586,-382.285
            c-0.066,-0.326,-0.189,-0.642,-0.36,-0.926z"/>
        </clipPath>
        <clipPath id="pilsner-glass">
          <path transform="scale(0.808)"
            d="M335,0
  c2.626,-0.088,4.32,3.182,2.732,5.276c-0.196,0.247,-0.386,0.511,-0.423,0.834c-0.24,61.83,-2.541,126.585,-9.551,188.004c-10.555,90.178,-32.604,184.893,-32.949,275.922c-0.159,31.468,8.152,58.045,24.769,84.641c2.933,5.143,5.436,10.911,4.93,16.955c-0.46,7.153,-6.071,9.268,-12.457,9.673c-0.889,0.165,-2.002,0.053,-2.903,0.143c-1.632,-0.059,-158.307,0.113,-159.803,-0.061c-5.673,-0.252,-12.376,-1.542,-13.489,-8.127c-1.028,-5.395,0.7,-10.893,3.134,-15.677c21.765,-36.161,27.509,-59.779,26.227,-101.93c-2.767,-85.875,-22.701,-176.333,-32.852,-261.539c-7,-61.392,-9.325,-126.181,-9.546,-187.976c-0.032,-0.295,-0.184,-0.568,-0.375,-0.79c-1.463,-1.836,-0.357,-4.826,1.95,-5.267c0.524,-0.608,209.974,0.267,210.606,-0.081z"/>
        </clipPath>
        <clipPath id="pilsner-fill">
          <path transform="scale(0.808)"
            d="M332,0
  c-2.032,57.921,-2.003,118.753,-8.534,176.675
  c-9.559,104.104,-40.502,211.495,-32.977,316.486c1.035,8.302,3.127,16.551,5.468,24.568
  c0.425,1.821,0.663,3.731,0.36,5.591c-3.898,15.328,-24.097,18.029,-37.439,19.113
  c-16.978,1.134,-35.367,0.979,-52.373,0.372
  c-14.212,-0.877,-34.765,-1.998,-42.307,-16.119c-1.672,-3.2,-1.385,-6.932,-0.38,-10.292
  c7.184,-22.615,7.418,-46.752,6.419,-70.252c-3.912,-88.734,-25.136,-181.234,-34.269,-269.466
  c-5.794,-57.356,-7.743,-117.153,-7.847,-176.5z" />
        </clipPath>
        <clipPath id="nonic-glass">
          <path transform="scale(1.077)"
            d="M292,0
            c2.765,-0.093,4.576,3.324,2.945,5.56c-0.191,0.302,-0.522,0.546,-0.602,0.913c-0.496,11.689,-5.256,57.742,-3.957,68.45c0.567,8.341,2.83,18.036,3.602,26.368c0.576,6.367,0.361,12.851,-0.789,19.144c-2.636,13.45,-9.001,26.899,-10.179,40.657c-0.025,0.823,-0.247,2.055,-0.261,2.869c-0.015,0.295,-19.32,263.118,-19.344,263.388c-0.382,4.242,-4.041,7.837,-8.291,8.137c-0.962,0.545,-165.661,-0.315,-166.725,0.03c-4.331,-0.074,-8.226,-3.569,-8.751,-7.872c-0.124,-2.37,-19.368,-262.943,-19.499,-265.128c-0.983,-15.283,-8.515,-29.858,-10.768,-44.879c-2.289,-13.755,1.857,-27.913,3.162,-41.526c1.467,-11.527,-3.275,-57.406,-3.845,-69.577c-0.048,-0.276,-0.229,-0.505,-0.415,-0.705c-1.693,-1.921,-0.572,-5.239,1.941,-5.736c0.304,-0.828,241.275,0.402,241.776,-0.093z"/>
        </clipPath>
        <clipPath id="nonic-fill">
          <path transform="scale(1.077)"
            d="M289,0
            c0.11,-0.031,-4.289,56.867,-4.197,56.935c-0.498,6.68,-0.318,13.468,0.464,20.12c0.792,7.357,2.835,16.164,3.296,23.552c0.596,8.178,-0.49,16.436,-2.91,24.258
            c-3.664,11.459,-7.703,23.672,-8.46,35.708c-0.212,1.26,-17.176,235.733,-17.427,236.809c-0.356,4.383,-3.175,7.665,-7.388,8.841
            c-7.892,2.324,-16.462,2.205,-24.62,2.591c-35.349,0.197,-85.762,0.905,-121.023,-0.399c-6.88,-0.781,-19.315,-0.305,-21.863,-8.316
            c-0.33,-0.978,-0.52,-2.024,-0.643,-3.048c-0.307,-2.288,-17.212,-235.267,-17.474,-237.589c-0.88,-14.558,-7.325,-28.551,-10.363,-42.66
            c-3.337,-15.33,2.086,-30.918,2.945,-46.195c0.94,-10.028,-3.74,-59.77,-4.291,-70.555
            z"/>
        </clipPath>
        <clipPath id="willibecher-glass">
          <path transform="scale(0.99)"
            d="M291,0c0.652,-0.001,1.303,0.185,1.856,0.531c0.587,0.364,1.062,0.908,1.345,1.538c0.278,0.615,0.373,1.313,0.268,1.98c-0.104,0.676,-0.411,1.32,-0.873,1.825c2.552,23.968,4.263,49.131,4.698,73.189c0.351,15.027,0.096,35.585,-0.245,50.792c-3.219,105.107,-15.747,227.81,-25.681,332.892c-0.123,1.243,-0.448,2.469,-0.957,3.61c-0.582,1.306,-1.409,2.506,-2.422,3.515c-2.041,2.046,-4.862,3.284,-7.749,3.399h-146.253c-2.887,-0.116,-5.709,-1.353,-7.749,-3.399c-1.013,-1.009,-1.84,-2.209,-2.422,-3.515c-0.509,-1.141,-0.834,-2.367,-0.957,-3.61c-9.942,-105.19,-22.452,-227.649,-25.682,-332.892c-0.326,-14.913,-0.608,-35.869,-0.245,-50.792c0.429,-24.037,2.155,-49.268,4.698,-73.19c-0.551,-0.602,-0.879,-1.401,-0.911,-2.216c-0.036,-0.823,0.23,-1.654,0.737,-2.303c0.442,-0.57,1.065,-0.998,1.757,-1.205c0.255,-0.077,0.52,-0.125,0.786,-0.142c0.325,-0.029,205.69,0.006,206.001,-0.007z"/>
        </clipPath>
        <clipPath id="willibecher-fill">
          <path transform="scale(0.99)"
            d="M288,0
            c-0.36,0.665,-0.495,1.449,-0.379,2.196
            c3.629,32.503,5.316,66.75,5.1,99.468c-0.475,88.039,-11.844,202.448,-19.511,290.827
            c-0.662,6.134,-2.36,12.483,-6.58,17.162c-2.34,2.63,-5.348,4.593,-8.531,6.056c-2.668,1.235,-5.544,2.182,-8.368,2.997
            c-5.091,1.457,-10.427,2.476,-15.669,3.21c-15.035,2.04,-30.696,2.312,-45.857,2.364c-15.163,-0.052,-30.82,-0.323,-45.857,-2.364
            c-5.24,-0.734,-10.579,-1.753,-15.669,-3.21c-2.821,-0.816,-5.7,-1.762,-8.368,-2.997
            c-3.182,-1.463,-6.191,-3.426,-8.531,-6.056
            c-4.218,-4.679,-5.918,-11.027,-6.58,-17.162c-7.689,-88.714,-19.019,-202.534,-19.511,-290.828
            c-0.215,-32.717,1.471,-66.963,5.101,-99.468c0.116,-0.747,-0.02,-1.531,-0.379,-2.196z"/>
        </clipPath>
        <clipPath id="weizen-glass">
          <path transform="scale(0.847)"
            d="M312,0
            c0.92,-0.004,1.827,0.439,2.386,1.17c0.299,0.386,0.503,0.846,0.586,1.327c0.106,0.601,0.023,1.232,-0.233,1.785c-0.058,0.135,-0.143,0.253,-0.191,0.392c-0.053,0.147,-0.076,0.305,-0.067,0.461c2.682,21.38,4.086,43.859,4.535,65.397c0.592,29.829,-0.465,61.786,-2.554,91.558c-1.448,19.371,-3.61,39.909,-6.766,59.052c-1.246,7.605,-2.938,16.68,-4.536,24.223c-4.288,20.728,-14.207,57.017,-18.624,77.731c-2.958,13.84,-5.166,28.376,-6.32,42.484c-2.646,33.24,-0.509,67.087,7.322,99.534c3.609,14.759,8.131,29.587,14.922,43.221c2.173,4.302,6.986,13.261,8.771,17.569c1.184,2.789,2.207,5.705,2.902,8.655c0.618,2.642,1.022,5.376,0.936,8.095c-0.067,1.929,-0.392,3.885,-1.205,5.648c-1.854,4.038,-5.693,5.522,-9.913,5.634h-174.874c-4.221,-0.111,-8.06,-1.597,-9.913,-5.634c-0.782,-1.695,-1.112,-3.572,-1.196,-5.428c-0.112,-2.631,0.249,-5.282,0.819,-7.846c0.697,-3.115,1.762,-6.187,3.01,-9.124c1.77,-4.287,6.636,-13.335,8.771,-17.569c6.791,-13.636,11.314,-28.457,14.922,-43.221c4.934,-20.438,7.636,-41.572,8.242,-62.584c0.512,-16.73,-0.236,-33.825,-2.316,-50.436c-0.966,-7.736,-2.369,-16.321,-3.887,-23.967c-2.519,-13.346,-12.289,-51.235,-15.675,-65.113c-3.716,-15.564,-7.145,-32.286,-9.5,-48.076c-4.085,-26.683,-6.255,-55.162,-7.485,-82.157c-1.449,-33.944,-1.578,-69.256,1.156,-103.111c0.589,-7.309,1.619,-17.29,2.522,-24.57c0.003,-0.113,-0.011,-0.227,-0.04,-0.337c-0.03,-0.121,-0.087,-0.231,-0.148,-0.338c-0.087,-0.165,-0.16,-0.342,-0.215,-0.521c-0.29,-0.921,-0.099,-1.975,0.497,-2.735c0.404,-0.523,0.985,-0.908,1.625,-1.073c0.2,-0.051,0.409,-0.085,0.615,-0.094c0.109,-0.045,190.99,0.027,191.119,-0.002z"/>
        </clipPath>
        <clipPath id="weizen-fill">
          <path transform="scale(0.847)"
            d="M124,0
            c-2.082,15.974,-3.485,32.951,-4.208,49.039
            c-0.966,20.624,-0.913,43.729,-0.334,64.412c0.764,24.181,2.285,50.283,4.983,74.316c1.833,16.195,4.354,32.885,7.606,48.855c5.603,28.159,16.143,63.458,21.435,91.666
            c2.45,13.191,4.17,26.912,4.977,40.304c1.976,33.352,-0.787,67.166,-9.224,99.529c-1.112,4.475,-2.985,10.423,-4.063,14.866c0,0,-0.091,0.427,-0.091,0.427
            s-0.042,0.208,-0.042,0.208l-0.039,0.204c-0.092,0.487,-0.173,1.027,-0.217,1.524c-0.07,0.792,-0.061,1.617,0.093,2.4c0.133,0.692,0.376,1.365,0.694,1.993
            c1.476,2.876,4.298,4.8,7.138,6.184c4.837,2.304,10.186,3.392,15.455,4.181c7.762,1.13,15.987,1.49,23.825,1.787c15.737,0.438,33.272,0.449,49.01,0
            c7.41,-0.299,15.269,-0.62,22.619,-1.619c5.002,-0.691,10.076,-1.635,14.781,-3.519c2.536,-1.031,5.022,-2.356,6.99,-4.287c1.005,-0.995,1.858,-2.168,2.369,-3.493
            c0.299,-0.773,0.461,-1.597,0.485,-2.426c0.046,-2.006,-0.521,-4.018,-1.027,-5.945c-1.47,-5.252,-3.638,-12.778,-4.815,-17.91c-2.071,-8.643,-3.815,-17.673,-5.11,-26.465
            c-3.809,-26.131,-4.341,-52.87,-1.859,-79.155c1.063,-11.008,2.782,-22.441,4.939,-33.287c5.301,-26.37,15.315,-60.797,20.569,-87.169c3.25,-15.968,5.776,-32.663,7.606,-48.855
            c3.714,-33.692,5.3,-69.152,5.426,-103.05c-0.046,-28.022,-1.319,-56.926,-4.987,-84.714z"/>
        </clipPath>
      <linearGradient :id="id" x1="0" x2="0" y1="0" :y2="fillEnd[design]">
        <stop stop-color="white" stop-opacity="0" offset="0"/>
        <stop stop-color="white" stop-opacity="0" :offset="1-fill"/>
        <stop stop-color="white" stop-opacity="1" :offset="1-fill"/>
        <stop stop-color="white" stop-opacity="1" :offset="1-0.85*fill"/>
        <stop :stop-color="color" stop-opacity="1" :offset="1-0.85*fill"/>
        <stop :stop-color="color" offset="1"/>
      </linearGradient>

      <linearGradient id="glassGradient" x1="12%" x2="88%" y1="0%" y2="0%">
        <stop stop-color="rgb(0, 0, 0)" stop-opacity="0.5" offset="0%"/>
        <stop stop-color="rgb(127, 127, 127)" stop-opacity="0.15" offset="22%"/>
        <stop stop-color="rgb(255, 255, 255)" stop-opacity="0.45" offset="30%"/>
        <stop stop-color="rgb(255, 255, 255)" stop-opacity="0" offset="60%"/>
        <stop stop-color="rgb(0, 0, 0)" stop-opacity="0" offset="75%"/>
        <stop stop-color="rgb(0, 0, 0)" stop-opacity="0.50" offset="100%"/>
      </linearGradient>
          <marker id="markerCircle" markerWidth="8" markerHeight="8" refX="5" refY="5">
        <circle cx="5" cy="5" r="3" style="stroke: none; fill:#000000;"/>
    </marker>

    <marker id="markerArrow" markerWidth="33" markerHeight="33" refX="0" refY="0"
           orient="auto">
        <path  d="M0,0 l0,30" style="stroke: black;" />
    </marker>
      </defs>

  <!-- <rect x="0" y="0" width="100%" height="100%" fill="rgb(255,190,190)" />
  <rect x="12%" y="0" width="76%" height="90.1%" fill="rgb(255,255,190)" /> -->
  <rect x="0" y="0" width="100%" height="100%" :clip-path="'url(#' + design + '-glass)'" fill="rgb(190,190,190)" />
  <rect x="0" y="0" width="100%" height="100%" :clip-path="'url(#' + design + '-fill)'" :fill="'url(#' + id + ')'" />
  <rect x="0" y="0" width="100%" height="100%" :clip-path="'url(#' + design + '-glass)'" fill="url(#glassGradient)" />

<!--
<g >


<path stroke="green" fill="transparent" transform="scale(0.847)" marker-start="url(#markerArrow)"
  d="M124,0
  c-2.082,15.974,-3.485,32.951,-4.208,49.039
  c-0.966,20.624,-0.913,43.729,-0.334,64.412c0.764,24.181,2.285,50.283,4.983,74.316c1.833,16.195,4.354,32.885,7.606,48.855c5.603,28.159,16.143,63.458,21.435,91.666
  c2.45,13.191,4.17,26.912,4.977,40.304c1.976,33.352,-0.787,67.166,-9.224,99.529c-1.112,4.475,-2.985,10.423,-4.063,14.866c0,0,-0.091,0.427,-0.091,0.427
  s-0.042,0.208,-0.042,0.208l-0.039,0.204c-0.092,0.487,-0.173,1.027,-0.217,1.524c-0.07,0.792,-0.061,1.617,0.093,2.4c0.133,0.692,0.376,1.365,0.694,1.993
  c1.476,2.876,4.298,4.8,7.138,6.184c4.837,2.304,10.186,3.392,15.455,4.181c7.762,1.13,15.987,1.49,23.825,1.787c15.737,0.438,33.272,0.449,49.01,0
  c7.41,-0.299,15.269,-0.62,22.619,-1.619c5.002,-0.691,10.076,-1.635,14.781,-3.519c2.536,-1.031,5.022,-2.356,6.99,-4.287c1.005,-0.995,1.858,-2.168,2.369,-3.493
  c0.299,-0.773,0.461,-1.597,0.485,-2.426c0.046,-2.006,-0.521,-4.018,-1.027,-5.945c-1.47,-5.252,-3.638,-12.778,-4.815,-17.91c-2.071,-8.643,-3.815,-17.673,-5.11,-26.465
  c-3.809,-26.131,-4.341,-52.87,-1.859,-79.155c1.063,-11.008,2.782,-22.441,4.939,-33.287c5.301,-26.37,15.315,-60.797,20.569,-87.169c3.25,-15.968,5.776,-32.663,7.606,-48.855
  c3.714,-33.692,5.3,-69.152,5.426,-103.05c-0.046,-28.022,-1.319,-56.926,-4.987,-84.714z"/>

  </g>

  <path stroke="RED" fill="transparent" transform="scale(0.847)"
   d="M312,0
   c0.92,-0.004,1.827,0.439,2.386,1.17c0.299,0.386,0.503,0.846,0.586,1.327c0.106,0.601,0.023,1.232,-0.233,1.785c-0.058,0.135,-0.143,0.253,-0.191,0.392c-0.053,0.147,-0.076,0.305,-0.067,0.461c2.682,21.38,4.086,43.859,4.535,65.397c0.592,29.829,-0.465,61.786,-2.554,91.558c-1.448,19.371,-3.61,39.909,-6.766,59.052c-1.246,7.605,-2.938,16.68,-4.536,24.223c-4.288,20.728,-14.207,57.017,-18.624,77.731c-2.958,13.84,-5.166,28.376,-6.32,42.484c-2.646,33.24,-0.509,67.087,7.322,99.534c3.609,14.759,8.131,29.587,14.922,43.221c2.173,4.302,6.986,13.261,8.771,17.569c1.184,2.789,2.207,5.705,2.902,8.655c0.618,2.642,1.022,5.376,0.936,8.095c-0.067,1.929,-0.392,3.885,-1.205,5.648c-1.854,4.038,-5.693,5.522,-9.913,5.634h-174.874c-4.221,-0.111,-8.06,-1.597,-9.913,-5.634c-0.782,-1.695,-1.112,-3.572,-1.196,-5.428c-0.112,-2.631,0.249,-5.282,0.819,-7.846c0.697,-3.115,1.762,-6.187,3.01,-9.124c1.77,-4.287,6.636,-13.335,8.771,-17.569c6.791,-13.636,11.314,-28.457,14.922,-43.221c4.934,-20.438,7.636,-41.572,8.242,-62.584c0.512,-16.73,-0.236,-33.825,-2.316,-50.436c-0.966,-7.736,-2.369,-16.321,-3.887,-23.967c-2.519,-13.346,-12.289,-51.235,-15.675,-65.113c-3.716,-15.564,-7.145,-32.286,-9.5,-48.076c-4.085,-26.683,-6.255,-55.162,-7.485,-82.157c-1.449,-33.944,-1.578,-69.256,1.156,-103.111c0.589,-7.309,1.619,-17.29,2.522,-24.57c0.003,-0.113,-0.011,-0.227,-0.04,-0.337c-0.03,-0.121,-0.087,-0.231,-0.148,-0.338c-0.087,-0.165,-0.16,-0.342,-0.215,-0.521c-0.29,-0.921,-0.099,-1.975,0.497,-2.735c0.404,-0.523,0.985,-0.908,1.625,-1.073c0.2,-0.051,0.409,-0.085,0.615,-0.094c0.109,-0.045,190.99,0.027,191.119,-0.002z"/>
 -->
  </svg>
</template>

<script>
export default {
  name: 'glass',
  props: {
    fill: {type: Number},
    color: {type: String},
    id: {type: String},
    design: {type: String, default: 'nonic'}
  },
  data: function() {
    return {
      fillStart: {'tulip': 0, 'irish': 0, 'ipa': 0, 'american': 0, 'pilsner': 0, 'nonic': 0, 'willibecher': 0, 'weizen': 0},
      fillEnd: {'tulip': 0.7, 'irish': 0.95, 'ipa': 0.948, 'american': 0.932, 'pilsner': 0.93, 'nonic': 0.934, 'willibecher': 0.892, 'weizen': 0.901}
    }
  }
}
</script>